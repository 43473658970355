<template>
  <div>
    <paypal :order="order" :totalAfterTax="getTotal" :orderId="orderId"></paypal>
  </div>
</template>

<script>
import paypal from "../paypal.vue";
export default {
  name: "checkout",
  props: {
      order: Array,
      orderId: Number
  },
  computed:{
    getTotal(){
      var total =0;
      for(let i=0; i<this.order.length; i++){
        var orderItem = this.order[i];
        total += orderItem.count * orderItem.unitPrice;
      }
      var tax = total *0.05;
      total += tax;
      total = parseFloat(total).toFixed(2)
      return total;
    },
  },
  components: {
    paypal,
  },
  data() {
    return {
      
    };
  },
  methods: { 
   
  },
};
</script>

<style scoped>
.all {
  text-align: center;
}
.form {
  margin-left: 28%;
}
.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;

  text-align: center;

  color: #23438e;
}
.subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #23438e;
}
.shape {
  width: 480px;
  height: 56px;
}
.botton {
  margin-top: 30px;
  width: 492px;
  height: 56px;
  background: #23438e;
  border-radius: 5px;
}
.button {
  text-align: center;
}
</style>
