<template>
  <div class="w-100">
    <div class="subtitle"><span> Your total is  {{ totalAfterTax }} € </span> </div>
       <br>
    <br>
    <div class="mx-auto w-50" ref="paypal"></div>
 
  
  </div>
</template>
<script>
import api from "../api/index";
export default {
  props: {
    totalAfterTax: String,
    order: Array,
    orderId: Number,
  },
  data() {
    return {
      loaded: false,
      showpaypal: false,
      loading: false,
      paidFor: false,
    };
  },
  mounted: function () {
    const script = document.createElement("script");
    //Sandbox clientId: ASOSFwcSd9MsuEbPqGLPdrZu9AQ5XpldgFgIxo-dB3qDqxCTLh7a2GPUE-8ufEnciPRRRonkv_EpJlRP
    script.src =
      "https://www.paypal.com/sdk/js?currency=EUR&client-id=ATpzR3k5E08SARiw43IjjthYkQk8eymTDhGaSRmCIwv_pUsvAVTlvsbKpIpo1eIi5nNq0zScDxQpmmya";
    script.addEventListener("load", this.setLoaded);
    document.body.appendChild(script);
  },

  methods: {
    makeToast(title, variant, textToShow) {
      this.$bvToast.toast(textToShow, {
        title: title,
        variant: variant,
        toaster: "b-toaster-top-center",
        solid: false,
      });
    },
    updateOrder(paypalDetails){
      try
      {
        console.log('The details now are ' + JSON.stringify(paypalDetails));
        var orderObject = 
        {
            orderDetails: JSON.stringify(this.order),
            amount: parseFloat(this.totalAfterTax),
            state: 4, //Success
            paypalOrderId: paypalDetails.id,
            paypalDetails: JSON.stringify(paypalDetails),
            error: ''
        };
        api.updateOrder(orderObject, this.orderId).then(() => {
           this.$router.push("/paymentSuccess");
        });
      }
      catch (error) 
      {
        console.error(error);
      }
    },
    setLoaded: function () {
      this.loaded = true;
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: "Altosio Order",
                  amount: {
                    currency_code: "EUR",
                    value: this.totalAfterTax,
                  },
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            try {
              const order = await actions.order.capture();+
              //Update the Order Table and assign the licenses
              this.updateOrder(order);
              //Maybe send an Email here??
             
            } catch (error) {
              this.makeToast('Something went wrong...', 'danger', 'Your order was not processed. Please try again later...' + error);
              console.error(error);
            }
          },
          onError: (err) => {
            this.makeToast('Something went wrong...', 'danger', 'Your order was not processed. Please try again later...' + JSON.stringify(err));
            console.log(err);
          },
          onCancel: () => {
            this.makeToast('Cancelled', 'info', 'Payment cancelled.');
          },
        })
        .render(this.$refs.paypal);
    },
  },
};
</script>
<style scoped>
.subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  text-align: center;
  line-height: 24px;
  color: #23438e;
}
</style>